<template>
  <div class="ticket">
    <!-- 第一部分 -->

    <Head :aircraftTicket="aircraftTicket[0][0]" />
    <PeoList
      :updateResult.sync="result"
      :seat="[coupons[chosenCoupon]]"
      :compliant="compliant"
      :explanationText="explanationText"
      @explanFinish="explanFinish"
    />

    <div class="gap"></div>
    <!-- 克隆的母体，选票的那个logo从这儿克隆 -->
    <Logo :corp="i" :id="i" style="display:none" v-for="i in allCorp" :key="i" />

    <!-- 第二部分 -->
    <van-panel title="选票信息">
      <template #header>
        <div class="panel-head">
          <span class="van-cell__title">选票信息</span>
          <Logo v-if="coupons[chosenCoupon]" :corp="coupons[chosenCoupon].corp" />
        </div>
      </template>
      <van-coupon-cell
        v-if="coupons[chosenCoupon]"
        class="chooseTicketBk"
        :title="coupons[chosenCoupon].seatClass+'（￥'+coupons[chosenCoupon].value+'）'"
        :value="'余票：'+(coupons[chosenCoupon].number>0?coupons[chosenCoupon].number:'充足')"
        @click="showList = true;checkClass()"
      />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="退改规则" name="1">
          <div class="rule">
            <van-row gutter="10">
              <van-col class="thead" span="7">时间点</van-col>
              <van-col class="thead" span="5">退票费</van-col>
              <van-col class="thead" span="5">同舱改期</van-col>
              <van-col class="thead" span="7" style="text-align:center">签转</van-col>
            </van-row>
            <van-row gutter="10" v-for="(item,i) in ruleList.rulePointList" :key="i">
              <van-col class="van-cell__label" span="7">{{item.content}}</van-col>
              <van-col class="van-cell__label" span="5">￥{{item.refundFee}}/人</van-col>
              <van-col class="van-cell__label" span="5">￥{{item.modifyFee}}/人</van-col>
              <van-col
                class="van-cell__label"
                span="7"
                style="text-align:center"
              >{{item.modifyStipulate}}</van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-panel>
    <el-tooltip class="item" effect="dark" :content="ruleList.baggage" placement="top">
      <van-notice-bar left-icon="volume-o" :text="'托运规则：'+ruleList.baggage" />
    </el-tooltip>

    <div class="gap"></div>

    <!-- 第三部分 -->
    <Insurance
      v-if="config_insurance&&coupons[chosenCoupon]"
      :updateInsuranceResult.sync="insuranceResult"
      :corp="coupons[chosenCoupon].corp"
    />

    <!-- 座次类型弹出层 -->
    <van-popup v-model="showList" round position="bottom" class="pick-ticket">
      <van-coupon-list
        enabled-title="有票"
        disabled-title="无票"
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        :disabled-coupons="disabledCoupons"
        :show-exchange-bar="false"
        :close-button-text="'确定'"
        @change="onChange"
      />
    </van-popup>

    <div class="gap"></div>

    <!-- 第三部分 -->
    <BodyList :totalPrice="totalPrice" />
    <div class="bottom-gap"></div>
    <!-- 第四部分 -->
    <van-submit-bar :price="totalPrice*100" button-text="提交占座" @submit="onSubmit">
      <template #tip>
        <div
          v-if="coupons[chosenCoupon]"
          style="text-align:right"
        >（票价{{coupons[chosenCoupon].value}}/人+机建50/人{{0 in insuranceResult?`+保险${insurancePrice}/人`:''}}{{config_serviceprice>0?'+服务费'+config_serviceprice+'/人':''}}）*{{result.length}}人</div>
      </template>
    </van-submit-bar>

    <!-- 遮罩层 -->
    <van-overlay :show="wrapper" @click="show = false">
      <div class="wrapper" @click.stop>
        <LoadingAnimate :text="'占座中'" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkSeat, CreateTrainPeoList } from "@/services/user";
import { createOrder, updataTicketDom } from "@/services/aircraft";
import {
  AircraftServiceProvider,
  NeedAircraftInsurance,
  ServicePrice,
} from "@/common/config";
import Logo from "@/components/Logo";
import Head from "./components/InfoHead";
import PeoList from "./components/PeoList";
import BodyList from "@/components/InfoList";
import Insurance from "@/components/Insurance";
import LoadingCircle from "@/components/LoadingCircle";
import LoadingAnimate from "@/components/LoadingAnimate";
import { getFlightModifyAndRefundRules } from "@/api/aircraft";

export default {
  components: {
    LoadingCircle,
    LoadingAnimate,
    Head,
    BodyList,
    PeoList,
    Insurance,
    Logo,
  },
  computed: {
    ...mapGetters([
      "aircraftTicket",
      "personList",
      "travelInfo",
      "aircraftOrderChange",
    ]),
    // 保险费
    insurancePrice: function () {
      let price = 0;
      this.insuranceResult.forEach((element) => {
        price += element.salePrice;
      });
      return price;
    },
    // 总票价
    totalPrice: function () {
      if (!this.coupons[this.chosenCoupon]) return 0;
      let price =
        this.coupons[this.chosenCoupon].value * this.result.length + //票价*人数
        this.result.length * 50; // 机建费
      return (
        price +
        this.insurancePrice * this.result.length +
        this.config_serviceprice * this.result.length
      );
    },
  },
  activated() {
    this.compliant = false;
    this.wrapper = false;
    this.showList = false;
    window.setTitle(this.aircraftTicket[0][0].flightNo);
    this.getSeatList();
    this.fetchRule();
  },
  data() {
    return {
      config_serviceprice: ServicePrice, // 服务费
      config_insurance: NeedAircraftInsurance, // 保险功能开关
      allCorp: AircraftServiceProvider,
      result: [], // 已选择的人员列表
      chosenCoupon: 0, // 可购列表选中的下标
      coupons: [], // 可购座位列表
      disabledCoupons: [], // 不可购列表
      ruleList: {}, // 退改签规则
      showList: false, // 选座弹出层
      compliant: false, // 超标填表开关
      wrapper: false, // 遮罩层
      wrapper1: false, // 遮罩层菊花瓣
      explanation: null, // 申请信息
      explanation1: "",
      explanationText: [],
      index: null, // 编辑框位置
      activeNames: [], // 折叠面板
      show: false,
      checked: true,
      insuranceResult: [], // 已选保险
    };
  },
  methods: {
    // 超额填表成功
    explanFinish(explanation, explanation1) {
      this.compliant = false;
      if (explanation && explanation1) {
        this.explanation = explanation;
        this.explanation1 = explanation1;
        this.grabSeat();
      }
    },
    // 生成两种座位列表
    getSeatList() {
      this.coupons = [];
      this.disabledCoupons = [];
      let minpeo = { stand: { allowBookHighest: 999999 } };
      this.personList.forEach((e) => {
        if (parseInt(e.stand.allowBookHighest) < minpeo.stand.allowBookHighest)
          minpeo = e;
      });
      this.aircraftTicket[0].forEach((first) => {
        first.flightSeat.forEach((element) => {
          element.description = checkSeat(minpeo, element, "aircraft")
            ? "无超标情况"
            : "有超标情况";
          element.condition = element.discount || " ";
          element.reason = "";
          element.corp = first.corp;
          element.name =
            element.seatClass +
            (element.number != 0
              ? " | 余票" + (element.number > 0 ? element.number : "充足")
              : "") +
            " | ";
          // first.corp;
          element.value = element.price.seatPrice;
          element.valueDesc = element.price.seatPrice;
          element.unitDesc = "元";
          element.number != 0
            ? this.coupons.push(element)
            : this.disabledCoupons.push(element);
        });
      });
      this.coupons.sort((a, b) => a.value - b.value);
      for (let i = 0; i < this.coupons.length; i++) {
        const element = this.coupons[i];
        if (element.corp == this.$route.query.corp)
          return (this.chosenCoupon = i);
      }
    },
    // 选票的钩子
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index >= 0 ? index : this.chosenCoupon;
      this.fetchRule();
    },
    // 拉取退改签规则
    fetchRule() {
      getFlightModifyAndRefundRules({
        bookInfo: this.coupons[this.chosenCoupon].bookInfoStr,
        corp: this.coupons[this.chosenCoupon].corp,
      }).then((response) => {
        this.ruleList = response.data;
      });
    },
    // 点击提交按钮，该方法仅做一些简单的验证，之后还需要调用合规检查，最后才可以提交占座
    onSubmit: async function () {
      this.explanationText = [];
      if (this.result.length <= 0)
        return this.$this.$toast.fail("尚未选择人员");
      //检查人员信息
      for (let i = 0; i < this.result.length; i++) {
        let element = this.result[i];
        if (
          !element.hasOwnProperty("tel") ||
          !element.hasOwnProperty("birthday") ||
          !element.hasOwnProperty("gender")
        )
          return this.$toast.fail("人员信息不完整");
      }
      // 行程单余额控制
      this.wrapper1 = true;
      let checkPrice = await this.$store.dispatch(
        "project/updateAppPrice",
        this.totalPrice
      );
      this.wrapper1 = false;
      if (this.travelInfo.isControl == "yes" && !checkPrice) {
        return this.$toast.fail("行程限额不足");
      }
      // 打开超标窗口
      this.result.forEach((element) => {
        if (!checkSeat(element, this.coupons[this.chosenCoupon], "aircraft")) {
          this.explanationText.push(element);
        }
      });
      if (this.explanationText.length > 0) {
        this.wrapper = false;
        this.compliant = true;
        return;
      }
      this.grabSeat();
    },
    // 抢座
    async grabSeat() {
      let seatInfo = this.coupons[this.chosenCoupon];
      this.wrapper = true;
      let flight = this.aircraftTicket[0].filter((item) => {
        return item.corp == seatInfo.corp;
      });
      let personList = CreateTrainPeoList(
        this.result,
        seatInfo,
        this.explanation,
        this.explanation1,
        "aircraft"
      );
      personList.forEach((element) => {
        element.AAI = 0 in this.insuranceResult; // 加保险
      });
      flight[0].flightSeat = [seatInfo];
      let req = {
        flightInfo: flight,
        travelUser: personList,
        contactPerson: this.travelInfo.loginUser,
        orderTotal: this.totalPrice.toString(),
        applyNo: this.travelInfo.appId,
      };
      let ticket1 = await createOrder(req, this.$route.name);
      this.wrapper = false;
      if (ticket1) {
        this.$router.push({ path: "/plan" }); // 转到订单列表
        // 下方转到详情（弃用）
        // this.$router.push({
        //   path: "/paymentAircraft",
        //   query: {
        //     corp: ticket1.corp,
        //     orderNo: ticket1.orderSerialNo,
        //     outOrderNo: ticket1.outOrderNo,
        //   },
        // });
      }
    },
    // 操作dom 超标票变红
    checkClass() {
      updataTicketDom(this.coupons);
    },
  },
};
</script>

<style lang="less" src="@/styles/orderCommon.less"></style>
<style lang="less">
.rule {
  .van-panel__header {
    padding: 5px 16px;
  }
}
.wrapper .wrapper {
  top: 74% !important;
}
</style>
<style lang="less" scoped>
.rule {
  padding: 0 16px;
  padding-bottom: 10px;
  .thead {
    font-size: 14px;
    color: #444;
  }
}
.search {
  font-size: 17px;
}
.content {
  padding: 16px 16px 90px;
}

.text {
  transition: all 2s;
  font-size: 14px;
  color: rgb(92, 92, 92);
  width: 97%;
}
.product {
  color: #1989fa;
  font-size: 16px;
}
.bigcheck {
  position: relative;
  top: -10px;
}
.bottom-gap {
  height: 6rem;
}
</style>
